
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import { IssueDetail, IssueTypeDetail } from '../../types/inspection'
import { DicInfo, DicList } from '../../types/common'
import { dayFormat } from '../../utils/formatDate'

@Component({
  name: 'inspectionList'
})
@KeepAlive
export default class List extends Vue {
  private searchInfo: { projectName: string; patrolType: string;issueStatus: string; issueTypeId: string; rectifierName: string; issueContent: string} = {
    projectName: '',
    patrolType: '', // 巡查类别
    issueStatus: '', // 问题状态
    issueTypeId: '', // 问题类型ID
    rectifierName: '', // 经办人模糊查询
    issueContent: '' // 整改内容模糊查询
  }

  private props = {
    label: 'issueTypeName',
    children: 'childNode',
    value: 'issueTypeId'
  }

  private dateRange: Array<string> = []
  private tableData: IssueDetail[]=[]

  private loading = false
  private dicList: DicInfo[] = []
  private issueTypeList: Array<IssueTypeDetail> = []
  private progressStatus = 'code'
  private progressTab: Array<{dicCode: string; count: number; dicValue: string}> = []
  private page = 1
  private size = 10
  private total = 0
  private countDownObj: any = {}
  // 定时器存储
  private intervalData: any = {}

  created (): void {
    this.getProgressTab()
    this.getCategories()
    this.getTypeList()
    this.getList()
  }

  destroyed () {
    for (const key in this.intervalData) {
      this.intervalData[key] && clearInterval(this.intervalData[key])
    }
  }

  getColor (id: number) {
    return `color${id}`
  }

  handleClick () {
    this.page = 1
    this.getList()
  }

  // 巡查类别
  getCategories () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'patrolType' }).then(res => {
      this.dicList = res.patrolType
    })
  }

  // 查询类型列表
  getTypeList () {
    this.$axios.get<IssueTypeDetail[]>(this.$apis.inspection.selectIssueTypeByList).then(res => {
      this.issueTypeList = res || []
    })
  }

  // 查询类型列表
  getProgressTab () {
    this.$axios.get(this.$apis.inspection.selectIssueSum, {
      ...this.searchInfo,
      startDate: this.dateRange && this.dateRange[0] ? this.dateRange[0] : '',
      endDate: this.dateRange && this.dateRange[1] ? this.dateRange[1] : ''
    }).then(res => {
      this.progressTab = res.list || []
      this.progressTab.unshift({ dicValue: '全部', count: res.total || 0, dicCode: '' })
    })
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<IssueDetail>}>(this.$apis.inspection.pageIssueListByNew,
      {
        ...this.searchInfo,
        startDate: this.dateRange && this.dateRange[0] ? this.dateRange[0] : '',
        endDate: this.dateRange && this.dateRange[1] ? this.dateRange[1] : '',
        issueStatus: this.progressStatus.split('code')[1],
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
      res.list.map((item: IssueDetail) => {
        if (item.issueId && this.getExtraTime(item.issueId) > 0) {
          this.setTime(item.issueId)
        }
      })
    }).finally(() => {
      this.loading = false
    })
  }

  // 问题删除
  delIssue (row: IssueDetail) {
    this.$confirm('确定要删除该问题吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.inspection.deleteIssue,
        {
          issueId: row.issueId
        }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }

  // 查看详情
  toDetail (row: IssueDetail) {
    this.$router.push({ name: 'inspectionIssueDetail', params: { issueId: row.issueId as string } })
  }

  onSearch (): void {
    this.page = 1
    this.getProgressTab()
    this.getList()
  }

  // 导出
  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.inspection.exportPatrolData, {
      ...this.searchInfo,
      startDate: this.dateRange && this.dateRange[0] ? this.dateRange[0] : '',
      endDate: this.dateRange && this.dateRange[1] ? this.dateRange[1] : '',
      issueStatus: this.progressStatus.split('code')[1],
      exportFlag: 0
    }).finally(() => {
      loading.close()
    })
  }

  // 催办点击
  onUrge (id: string) {
    this.$axios.post(this.$apis.inspection.insertIssueUrge, {
      issueId: id
    }).then(() => {
      this.$message.success('催办成功')
      this.setTime(id)
    })
  }

  // 获取存储时间
  getExtraTime (id: string) {
    return this.countDownObj[id] ? this.countDownObj[id] : 0
  }

  // 倒计时
  setTime (id: string) {
    this.intervalData[id] && clearInterval(this.intervalData[id])
    if (!this.countDownObj[id]) {
      this.$set(this.countDownObj, id, 60)
    }
    this.intervalData[id] = setInterval(() => {
      if (this.countDownObj[id] < 1) {
        delete this.countDownObj[id]
        clearInterval(this.intervalData[id])
      } else {
        this.countDownObj[id]--
      }
    }, 1000)
  }

  // 整改日期是否标红
  deadLineColor (item: IssueDetail) {
    return this.$dayjs(dayFormat(new Date().getTime())).isAfter(this.$dayjs(item.deadLine)) && item.issueStatus !== '2' && item.issueStatus !== '4' && item.issueStatus !== '5' ? 'red' : ''
  }
}
